@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;600&family=Montserrat:wght@300;600;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body,
#root,
.app {
  height: 100%;
  background-color: #00040d;
  @apply text-white;
}

.hover-bounce {
  @apply inline-block animate-extraRubber;
}

.yellow {
  @apply hover:text-yellow ease-in transition;
}
